import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import WaveBottom from "../components/Wave/WaveBottom";

import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function JobsPage() {
  const {
    markdownRemark: { frontmatter: pageInfo, html },
  } = useStaticQuery(graphql`
    query JobsPageQuery {
      markdownRemark(frontmatter: { pageName: { eq: "jobs" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [allValid, setAllValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);

  useEffect(() => {
    if (
      name !== "" &&
      message !== "" &&
      phone !== "" &&
      recaptchaPassed === true
    ) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  }, [name, message, phone, recaptchaPassed]);

  const handleSubmit = (e) => {
    const data = {
      name: name.trim(),
      phone: phone.trim(),
      message: message.trim(),
    };
    if (allValid) {
      setLoading(true);
      Axios.post("/.netlify/functions/sendEmail", JSON.stringify(data))
        .then((response) => {
          if (response.status !== 200) {
            setLoading(false);
            console.log("error sending message");
          } else {
            setLoading(false);
            setSendSucces(true);
            setName("");
            setPhone("");
            setMessage("");
            console.log("message sent");
          }
        })
        .catch((error) => console.log(error));
    }
    e.preventDefault();
  };

  return (
    <Layout>
      <SEO
        keywords={[`jobs`, `careers`, `apply`]}
        title="Jobs"
        description="Check our open positions"
      />
      <div className="bg-white px-4 pt-4 pb-12 text-black">
        <div className="container mx-auto px-4">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            {pageInfo.title}
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>

      <div className="bg-gray-300 px-4 pt-4 pb-8 text-black">
        <div className="container mx-auto px-4">
          <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-black">
            Apply
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <form>
            <div className="flex w-full flex-wrap">
              <div className="w-full md:w-1/2 ">
                <label
                  className="block mb-2 text-xs font-bold uppercase"
                  htmlFor="name"
                >
                  Name
                </label>

                <input
                  className="w-full mb-6 rounded-md border-gray-800"
                  id="name"
                  placeholder="Full Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/2 md:px-2">
                <label
                  className="block mb-2 text-xs font-bold uppercase"
                  htmlFor="phone"
                >
                  Phone
                </label>

                <input
                  className="w-full mb-6 rounded-md border-gray-800"
                  id="phone"
                  placeholder="502-888-8888"
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="message"
            >
              Additional Info
            </label>

            <textarea
              className="w-full mb-6 rounded-md border-gray-800"
              id="message"
              placeholder="Position applying for, qualifications, etc"
              rows="3"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <div className="py-4">
              <ReCAPTCHA
                sitekey="6LfcNRUaAAAAAAWa9okkTyNhoUP1Jqljz4zUTLjj"
                onChange={() => {
                  setRecaptchaPassed(true);
                }}
              />
            </div>

            <button
              className={`px-4 py-2 text-sm font-bold text-white rounded ${
                allValid
                  ? "bg-yellow-700 border-b-4 border-yellow-800  hover:border-yellow-700 hover:bg-yellow-600"
                  : "bg-gray-700 cursor-not-allowed"
              }`}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? "Sending..." : sendSuccess ? "Message Sent" : "Submit"}
            </button>
          </form>
        </div>
      </div>

      <WaveBottom color="#d2d5da" />
    </Layout>
  );
}

export default JobsPage;
